const state = {
  loaiDonVi: [
    { id: 1, name: "Chiến đấu", note: "" },
    { id: 2, name: "Đảm bảo, lưu trữ", note: "" },
    { id: 3, name: "Đào tạo nghiên cứu khoa học", note: "" },
  ],
  capDonVi: [
    { id: 1, name: "Tiểu đoàn", symbol: "", note: "" },
    { id: 2, name: "Trung đoàn", symbol: "e", note: "Trung đoàn" },
    { id: 3, name: "Sư đoàn", symbol: "", note: "" },
    { id: 4, name: "Lữ đoàn", symbol: "", note: "" },
    { id: 5, name: "Đại đội", symbol: "", note: "" },
  ],
  donVi: [
    {
      id: 1,
      name: "Binh chủng hóa học",
      children: [
        { id: 2, name: "CQ binh chủng" },
        { id: 3, name: "Kho K61" },
        { id: 4, name: "Kho K62" },
        { id: 5, name: "Kho K63" },
        { id: 6, name: "Kho K64" },
        {
          id: 7,
          name: "Lữu đoàn 86",
          children: [
            { id: 8, name: "Đại đội 66" },
            { id: 9, name: "Tiểu đoàn 901" },
            { id: 10, name: "Tiểu đoàn 902" },
          ],
        },
      ],
    },
  ],
};

const getters = {
  loaiDonVi: (state) => state.loaiDonVi,
  capDonVi: (state) => state.capDonVi,
  donVi: (state) => state.donVi,
};

const mutations = {
  // Loại đơn vị
  saveAddLoaiDV: (state, item) => {
    const max = Math.max(
      ...state.loaiDonVi.map((item) => {
        return item.id;
      })
    );
    state.loaiDonVi.push({ id: max + 1, name: item[0], note: item[1] });
  },
  saveEditLoaiDV: (state, item) => {
    const data = item;
    state.loaiDonVi.forEach((item) => {
      if (item.id === data[0]) {
        item.name = data[1];
        item.note = data[2];
        return;
      }
    });
  },
  deleteListItemLDV: (state, payload) => {
    payload.forEach((item) => {
      for (let i = 0; i < state.loaiDonVi.length; i++) {
        state.loaiDonVi[i].id === item.id
          ? state.loaiDonVi.splice(i, 1)
          : state.loaiDonVi;
      }
    });
  },
  // Cấp đơn vị
  saveAddCapDV: (state, item) => {
    const max = Math.max(
      ...state.capDonVi.map((item) => {
        return item.id;
      })
    );
    state.capDonVi.push({
      id: max + 1,
      name: item[0],
      symbol: item[1],
      note: item[2],
    });
  },
  saveEditCapDV: (state, item) => {
    const data = item;
    state.capDonVi.forEach((item) => {
      if (item.id === data[0]) {
        item.name = data[1];
        item.symbol = data[2];
        item.note = data[3];
        return;
      }
    });
  },
  deleteListItemCDV: (state, payload) => {
    payload.forEach((item) => {
      for (let i = 0; i < state.capDonVi.length; i++) {
        state.capDonVi[i].id === item.id
          ? state.capDonVi.splice(i, 1)
          : state.capDonVi;
      }
    });
  },
};

export default {
  state,
  getters,
  mutations,
};
