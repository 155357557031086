import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Notification from "vue-notification";

Vue.config.productionTip = false;

Vue.use(Notification);

new Vue({
  router,
  store,
  vuetify,
  Notification,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
