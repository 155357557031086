import Vue from "vue";
import Vuex from "vuex";

import XacNhanTrangBi from "./modules/Chung/XacNhanTrangBi";
import TrangBiKhiTai from "./modules/Trang-bi-khi-tai/TrangBiKhiTai";
import DonVi from "./modules/Don-vi/DonVi";
import map from "./modules/map";
import TaiKhoan from "./modules/Tai-khoan/TaiKhoan";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: {
      text: "",
      link: "",
      add: false,
      sub_text: "Thêm",
    },
  },
  getters: {
    title: (state) => state.title.text,
    link: (state) => state.title.link,
    add: (state) => state.title.add,
    sub_text: (state) => state.title.sub_text,
  },
  mutations: {
    setTitle(state, payload) {
      state.title = { ...payload };
    },
  },
  modules: {
    XacNhanTrangBi,
    TrangBiKhiTai,
    DonVi,
    TaiKhoan,
    map,
  },
});
