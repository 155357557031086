const state = {
  user: [
    { id: 1, name: "Nguyễn Quốc Khánh", avatar: "", gender: "Nam", unit: "" },
  ],
  userGroup: [
    { id: 1, name: "Biên tập", note: "" },
    { id: 2, name: "Quản lý", note: "" },
    { id: 3, name: "Người dùng", note: "" },
  ],
};

const getters = {
  user: (state) => state.user,
  userGroup: (state) => state.userGroup,
};

const mutations = {};

export default {
  state,
  getters,
  mutations,
};
