import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Dashboards/ThongKeTrangBi.vue"
      );
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "@/views/Dashboards/ThongKeTrangBi.vue"
      );
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "@/views/AboutView.vue");
    },
  },
  {
    path: "/map",
    name: "map",
    component: () => {
      return import("@/views/BanDo.vue");
    },
  },
  //Quẩn lý trang bị khí tài
  {
    path: "/accept-equip",
    name: "accept-equip",
    meta: {
      url: "/accept-equip",
      modelName: "xac-nhan-trang-bi",
    },
    component: () => {
      return import("@/views/Dashboards/XacNhanTrangBi.vue");
    },
  },
  {
    path: "/unit/equip-list",
    name: "equip-list",
    component: () => {
      return import("@/views/Quan-ly-trang-bi-khi-tai/TrangBiKhiTaiHoaHoc.vue");
    },
  },
  {
    path: "/type-equip-list",
    name: "type-equip-list",
    component: () => {
      return import(
        "@/views/Quan-ly-trang-bi-khi-tai/Danh-muc-chung-khi-tai/LoaiTrangBi.vue"
      );
    },
  },
  {
    path: "/qh-list",
    name: "qh-list",
    component: () => {
      return import(
        "@/views/Quan-ly-trang-bi-khi-tai/Danh-muc-chung-khi-tai/PhanCapChatLuong.vue"
      );
    },
  },
  {
    path: "/status-list",
    name: "status-list",
    component: () => {
      return import(
        "@/views/Quan-ly-trang-bi-khi-tai/Danh-muc-chung-khi-tai/TinhTrangTrangBi.vue"
      );
    },
  },
  {
    path: "/origin-list",
    name: "origin-list",
    component: () => {
      return import(
        "@/views/Quan-ly-trang-bi-khi-tai/Danh-muc-chung-khi-tai/XuatXu.vue"
      );
    },
  },
  //Quản lý đơn vị
  {
    path: "/unit-list",
    name: "unit-list",
    component: () => {
      return import("@/views/Quan-ly-don-vi/DonVi.vue");
    },
  },
  {
    path: "/type-unit-list",
    name: "type-unit-list",
    component: () => {
      return import(
        "@/views/Quan-ly-don-vi/Danh-muc-chung-don-vi/LoaiDonVi.vue"
      );
    },
  },
  {
    path: "/level-unit-list",
    name: "level-unit-list",
    component: () => {
      return import(
        "@/views/Quan-ly-don-vi/Danh-muc-chung-don-vi/CapDonVi.vue"
      );
    },
  },
  //Quản lý tài khoản
  {
    path: "/user-list",
    name: "user-list",
    component: () => {
      return import("@/views/Quan-ly-tai-khoan/NguoiDung.vue");
    },
    children: [
      {
        path: "/add-user",
        name: "add-user",
        component: () => {
          return import("@/views/Quan-ly-tai-khoan/ThemNguoiDung.vue");
        },
      },
    ],
  },
  {
    path: "/group-list",
    name: "group-list",
    component: () => {
      return import("@/views/Quan-ly-tai-khoan/NhomNguoiDung.vue");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
