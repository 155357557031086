const state = {
  ChoXacNhan: {
    id: null,
    name: "",
    unit: null,
    action: null,
  },
  DangChinhSua: {
    id: null,
    name: "",
    unit: null,
    action: null,
  },
  DaXacNhan: [
    { id: 1, name: "PNXM-1M GAZ 66", unit: "Nhà máy X61" },
    { id: 2, name: "Trạm tiêu độc trang dụng AGV-3U", unit: "Tiểu đoàn 906" },
    { id: 3, name: "Xe bọc thép trinh sát hóa học", unit: "Tiểu đoàn 905" },
    { id: 4, name: "Máy đo phóng xạ hóa học", unit: "Tiểu đoàn 905" },
    { id: 5, name: "Xe bọc thép trinh sát hóa học", unit: "Tiểu đoàn 905" },
    { id: 6, name: "Xe thả khói KH-1", unit: "Tiểu đoàn 906" },
    { id: 7, name: "Dầu đốt", unit: "Tiểu đoàn 906" },
    { id: 8, name: "ISUZU", unit: "Tiểu đoàn 905" },
    {
      id: 9,
      name: "Máy phát điện loại 5,8 KVA, 220V, 1 pha",
      unit: "Tiểu đoàn 906",
    },
  ],
};

const getters = {
  ChoXacNhan: (state) => state.ChoXacNhan,
  DangChinhSua: (state) => state.DangChinhSua,
  DaXacNhan: (state) => state.DaXacNhan,
};

export default {
  state,
  getters,
};
