<template>
    <v-app-bar app height="45px" elevation="0" style="background-color: white;">
        <v-toolbar-title
          class="title-bar"
          @click="$router.push({ name: $store.getters.link });">{{ $store.getters.title }}</v-toolbar-title>
          <v-toolbar-title
          v-if="$store.getters.add"
            class="title-bar"
            @click="$router.push({ name: 'add-user' });"> - Thêm người dùng</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-tooltip bottom color="grey darken-3">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-history</v-icon>
                </v-btn>
            </template>
            <span>Lịch sử</span>
        </v-tooltip>
        
        <v-tooltip bottom color="grey darken-3">
            <template v-slot:activator="{ on, attrs }">
                <v-badge color="red" content="0" overlap offset-x="22" offset-y="22">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-btn>
                </v-badge>
            </template>
            <span>Thông báo</span>
        </v-tooltip>

        <!-- <v-tooltip bottom color="grey darken-3">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  text
                  v-bind="attrs"
                  v-on="on" 
                  class="mx-2">
                    <v-avatar size="30" v-if="!mini" tile hre>
                        <img src="../assets/img/logo/user.png" alt="user">
                    </v-avatar>
                </v-btn>
            </template>
            <span>User</span>
        </v-tooltip> -->
       
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
                
                <v-btn icon text v-bind="attrs" v-on="on" class="mx-2">
                    <v-avatar size="30" v-if="!mini" tile hre>
                        <img src="../assets/img/logo/user.png" alt="user">
                    </v-avatar>
                </v-btn>
            </template>
        
            <v-card>
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar>
                            <img src="../assets/img/logo/user.png" alt="User">
                        </v-list-item-avatar>
        
                        <v-list-item-content>
                            <v-list-item-title>Quốc Khánh</v-list-item-title>
                        </v-list-item-content>
        
                    </v-list-item>
                </v-list>
        
                <v-divider></v-divider>
        
                <v-card-actions>
                    <v-list>
                        <v-list-item link>
                            Chỉnh sửa thông tin
                        </v-list-item>
                        <v-list-item link>
                            Đăng xuất
                        </v-list-item>
                    </v-list>
                </v-card-actions>
            </v-card>
        </v-menu>
    </v-app-bar>
</template>

<script>
import store from '@/store';

export default {
    name: "Header",
    props: ['drawer'],
    data: () => ({
        fav: true,
        menu: false,
        message: false,
        hints: true,
    }),
    methods: {
        goTo() {
            this.$router.push({name : 'qh-list'});
        }
    }
}

</script>

<style  scoped>
.title-bar{
    font-weight: 600;
    color: #009ef7;
    font-size: 1rem;
    cursor: pointer;
}

</style>
