<template>
  <v-app>
    <Header/>
    <Nav/>
    <SnackBar/>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import Header from './components/Header.vue';
import Nav from './components/Nav.vue';
import SnackBar from './components/SnackBar.vue';

export default {
  name: "App",
  // data: () => ({
  //   drawer: true,
  //   mini : true,
  // }),
  components: { Header, Nav, SnackBar }
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
  max-width: 1750px;
}
}


</style>
