<template>
   <v-snackbar
    :color="snackbar.color"
    dark
    top
    right
    absolute
    centered
    outline
    style="z-index: 1000;"
    :timeout="snackbar.timeout"
    v-model="snackbar.state"
    
   >
    <v-icon class="mr-3">{{ snackbar.icon }}</v-icon>
    <strong>{{ snackbar.message }}</strong>
    <template v-slot:action="{attrs}">
       <v-btn icon v-bind="attrs" @click="$event => hideSnackbar({ state : false})">
            <v-icon>mdi-close-circle</v-icon>
       </v-btn>
    </template>
   </v-snackbar> 
</template>

<script>
import { mapGetters , mapMutations } from 'vuex';
export default {
    name : "SnackBar",
    computed: {
        ...mapGetters("map", {
            snackbar : "snackbar",
        }),
    },
    methods: {
        ...mapMutations("map", {
            hideSnackbar : "TOGGLE_SNACKBAR",
        }),
    }
}
</script>

<style lang="scss" scoped>

</style>