const state = {
  loaiTB: [
    { id: 1, name: "Khói lửa", note: "Test note" },
    { id: 2, name: "Tiêu tẩy", note: "" },
    { id: 3, name: "Xe cơ sở", note: "" },
    { id: 4, name: "Xe thả khói", note: "" },
    { id: 5, name: "Hệ thống cung cấp chất tạo khói", note: "" },
    { id: 6, name: "Hệ thống gia nhiệt và hóa hơi chất tạo khói", note: "" },
    { id: 7, name: "Hệ thống điện động lực và điều khiển", note: "" },
    { id: 8, name: "Hệ thống tản nhiệt thông gió", note: "" },
    { id: 9, name: "Tring sát phóng xạ", note: "" },
    { id: 10, name: "Trinh sát hóa học", note: "" },
    { id: 11, name: "Đề phòng", note: "" },
    { id: 12, name: "Khí tượng", note: "" },
    { id: 13, name: "Thông tin", note: "" },
    { id: 14, name: "Tác chiến", note: "" },
    { id: 15, name: "Xe phát lực", note: "" },
    { id: 16, name: "Khí tài", note: "" },
    { id: 17, name: "Sửa chữa", note: "" },
    { id: 18, name: "Thiết bị lấy mẫu", note: "" },
    { id: 19, name: "Xe trinh sát", note: "" },
    { id: 20, name: "Cắm cờ dấu", note: "" },
  ],
  phanCapCL: [
    { id: 1, name: "Cấp 3", note: "" },
    { id: 2, name: "Cấp 4", note: "" },
    { id: 3, name: "Cấp 2", note: "Trang bị đưa vào sử dụng dưới 2 năm" },
    { id: 4, name: "Cấp 5", note: "Trang bị chuẩn bị loại biên" },
    { id: 5, name: "Cấp 1", note: "Trang bị mới mua" },
  ],
  tinhTrangTB: [
    { id: 1, name: "Hỏng hóc", note: "Trang bị đang hỏng", color: "red" },
    {
      id: 2,
      name: "Đang hoạt động",
      note: "Trang bị đang hoạt động",
      color: "green",
    },
    {
      id: 3,
      name: "Đang sửa chữa",
      note: "Trang bị đang sửa chữa",
      color: "yellow darken-1",
    },
    { id: 4, name: "Loại biên", note: "Trang bị loại biên", color: "grey" },
  ],
  xuatXu: [
    { id: 1, name: "Pháp", note: "" },
    { id: 2, name: "Việt Nam", note: "" },
    { id: 3, name: "Nga", note: "" },
    { id: 4, name: "Nhật Bản", note: "" },
    { id: 5, name: "Trung Quốc", note: "Made in China" },
    { id: 6, name: "Đức", note: "Made in Germani" },
  ],
  trangBiHoaHoc: [
    {
      id: 1,
      name: "PNXM-1M GAZ 66",
      code: "BH 50-63",
      unit: "Nhà máy X61",
      status: "Đang hoạt động",
      color: "green",
    },
    {
      id: 2,
      name: "Trạm tiêu độc trang dụng cụ AGV-3U",
      code: "BH 52-22",
      unit: "Nhà máy X61",
      status: "Đang hoạt động",
      color: "green",
    },
    {
      id: 3,
      name: "Xe bọc thép trinh sát hóa học",
      code: "BH 40-167",
      unit: "Nhà máy X61",
      status: "Đang hoạt động",
      color: "green",
    },
    {
      id: 4,
      name: "Xe bọ thép trinh sát phóng xạ hóa học",
      code: "BH 40-203",
      unit: "Tiểu đoàn 905",
      status: "Loại biên",
      color: "grey",
    },
    {
      id: 5,
      name: "Xe thả khói KH-1",
      code: "KH-1",
      unit: "Tiểu đoàn 906",
      status: "Đang hoạt động",
      color: "green",
    },
    {
      id: 6,
      name: "Xe tiêu tẩy ARS-14",
      code: "T-14D",
      unit: "Tiểu đoàn 905",
      status: "Đang sửa chữa",
      color: "yellow",
    },
    {
      id: 7,
      name: "PNXM-1M GAZ 66",
      code: "BH 50-63",
      unit: "Nhà máy X61",
      status: "Hỏng hóc",
      color: "red",
    },
    {
      id: 8,
      name: "PNXM-1M GAZ 66",
      code: "BH 50-63",
      unit: "Nhà máy X61",
      status: "Loại biên",
      color: "grey",
    },
  ],
};

const getters = {
  loaiTB: (state) => state.loaiTB,
  phanCapCL: (state) => state.phanCapCL,
  tinhTrangTB: (state) => state.tinhTrangTB,
  xuatXu: (state) => state.xuatXu,
  trangBiHoaHoc: (state) => state.trangBiHoaHoc,
};

const mutations = {
  // loại trang bị
  saveAddLoaiTB: (state, item) => {
    const max = Math.max(
      ...state.loaiTB.map((item) => {
        return item.id;
      })
    );
    state.loaiTB.push({ id: max + 1, name: item[0], note: item[1] });
  },
  saveEditLoaiTB: (state, item) => {
    const data = item;
    state.loaiTB.forEach((item) => {
      if (item.id === data[0]) {
        item.name = data[1];
        item.note = data[2];
        return;
      }
    });
  },
  deleteListItemLTB: (state, payload) => {
    payload.forEach((item) => {
      for (let i = 0; i < state.loaiTB.length; i++) {
        state.loaiTB[i].id === item.id
          ? state.loaiTB.splice(i, 1)
          : state.loaiTB;
      }
    });
  },
  //Phan cấp chất lượng
  saveAddPhanCapCL: (state, item) => {
    const max = Math.max(
      ...state.phanCapCL.map((item) => {
        return item.id;
      })
    );
    state.phanCapCL.push({
      id: max + 1,
      name: item[0],
      note: item[1],
    });
  },
  saveEditPhanCapCL: (state, item) => {
    const data = item;
    state.phanCapCL.forEach((item) => {
      if (item.id === data[0]) {
        item.name = data[1];
        item.note = data[2];
        return;
      }
    });
  },
  deleteListItemPCCL: (state, payload) => {
    payload.forEach((item) => {
      for (let i = 0; i < state.phanCapCL.length; i++) {
        state.phanCapCL[i].id === item.id
          ? state.phanCapCL.splice(i, 1)
          : state.phanCapCL;
      }
    });
  },
  //Tình trạng trang bị
  saveAddTinhTrangTB: (state, item) => {
    const max = Math.max(
      ...state.tinhTrangTB.map((item) => {
        return item.id;
      })
    );
    state.tinhTrangTB.push({
      id: max + 1,
      name: item[0],
      note: item[1],
      color: item[2],
    });
  },
  saveEditTinhTrangTB: (state, item) => {
    const data = item;
    state.tinhTrangTB.forEach((item) => {
      if (item.id === data[0]) {
        item.name = data[1];
        item.note = data[2];
        item.color = data[3];
        return;
      }
    });
  },
  deleteItemTTTB: (state, id) => {
    for (let i = 0; i < state.tinhTrangTB.length; i++) {
      state.tinhTrangTB[i].id === id
        ? state.tinhTrangTB.splice(i, 1)
        : state.tinhTrangTB;
    }
  },
  deleteListItemTTTB: (state, payload) => {
    payload.forEach((item) => {
      for (let i = 0; i < state.tinhTrangTB.length; i++) {
        state.tinhTrangTB[i].id === item.id
          ? state.tinhTrangTB.splice(i, 1)
          : state.tinhTrangTB;
      }
    });
  },
  // Xuất xứ
  saveAddXuatXu: (state, item) => {
    const max = Math.max(
      ...state.xuatXu.map((item) => {
        return item.id;
      })
    );
    state.xuatXu.push({
      id: max + 1,
      name: item[0],
      note: item[1],
    });
  },
  saveEditXuatXu: (state, item) => {
    const data = item;
    state.xuatXu.forEach((item) => {
      if (item.id === data[0]) {
        item.name = data[1];
        item.note = data[2];
        return;
      }
    });
  },
  deleteListItemXX: (state, payload) => {
    payload.forEach((item) => {
      for (let i = 0; i < state.xuatXu.length; i++) {
        state.xuatXu[i].id === item.id
          ? state.xuatXu.splice(i, 1)
          : state.xuatXu;
      }
    });
  },
};

export default {
  state,
  getters,
  mutations,
};
