<template>
    <v-navigation-drawer :mini-variant.sync="mini" permanent app dark class="navigation-drawer">
        <v-list-item class="px-2 headerDrawer">
            <v-btn icon v-if="mini" @click.stop="mini = !mini">
                <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon href="/">
                <v-avatar size="50" v-if="!mini" hre>
                    <img src="../assets/img/logo/Logo_MTA.png" alt="Logo">
                </v-avatar>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="mini = !mini">
                <v-icon>mdi-chevron-double-left</v-icon>
            </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
            <template v-for="item in items">
                <v-list-item no-action>
                    <v-list-item-content v-if="!mini"
                    style="color: #8e8fa1;"
                      class="text-uppercase font-weight-bold">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-subheader class="text-uppercase font-weight-bold">{{ item.title }}</v-subheader> -->
                <v-list-item
                  v-for="item in item.children1"
                  v-if="item.children2.length === 0"
                  color="primary"
                  link
                  :to="{name : item.link}"
                    >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.title"
                      style="font-size: 1rem;"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                
                <v-list-group 
                v-else
                >
                    <!-- <template v-slot:prependIcon class="icon">
                        <v-icon v-text="item.icon"></v-icon>
                    </template> -->
                    <template v-slot:activator>
                        <v-icon v-text="item.icon" style="margin-right: 10px;"></v-icon>
                        <v-list-item-title style="font-size: 1rem;">{{ item.title }}</v-list-item-title>
                    </template>
                    <v-list-item v-for="item in item.children2" link :to="{name : item.link}">
                        <v-list-item-icon style="margin-left: 12px; margin-right: -4px;">
                            <v-icon size="4px">mdi-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
                
            </template>
            <v-list-item no-action>
                <v-list-item-content v-if="!mini" style="color: #8e8fa1;" class="text-uppercase font-weight-bold">
                    <v-list-item-title>Quản lý hệ thống</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link>
                <v-list-item-icon>
                    <v-icon>mdi-database-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title style="font-size: 1rem;">Thêm trường dữ liệu</v-list-item-title>
                </v-list-item-content>       
            </v-list-item>
            <!-- <v-btn text> <v-icon style="left: -2px; margin-right: 6px;">mdi-database-plus</v-icon> Thêm trường dữ liệu</v-btn> -->
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import store from "@/store";
import listIteam from "@/assets/static/listNav.json";

export default {
    name: "Nav",
    created() {
        this.items = listIteam.nav;
    },
    data() {
        return {
            drawer: null,
            items: null,
            mini: false,
        }
    },
    methods: {
        updateTitle(title) {
            // store.dispatch('updateTitle', title);
            store.commit('setTitle',title);
        }
    }
}

</script>

<style scoped>
.navigation-drawer {
    background-color: #1e1e2d;
}

.headerDrawer {
    height: 65px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 10px;
}

</style>