const state = {
  messages: {
    snackbar: {
      type: "info",
      color: "blue",
      icon: "mdi-info",
      message: "",
      state: false,
      timeout: 2000,
    },
  },
  isShowPopup: true,
};
const getters = {
  messages: (state) => state.messages,
  snackbar: (state) => state.messages.snackbar,
};
const mutations = {
  TOGGLE_SNACKBAR(state, payload) {
    state.messages.snackbar = { ...payload };
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
